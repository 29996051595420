import { CardScanView } from '@cardscan.ai/insurance-cardscan-react';
import ReactDOM from 'react-dom/client';
import Button from '../../components/Button';

const sessionToken = process.env.REACT_APP_SCANNER_TOKEN || 'secret_live_vd58gxX5b5xLg7Tn';
const showScanner = ({ onScanSuccess, onBack, onScanError }) => {
  const root = ReactDOM.createRoot(document.getElementById('scanner-root'));
  root.render(
    <CardScanView
      live={false}
      sessionToken={sessionToken}
      onSuccess={onScanSuccess}
      onError={onScanError}
      onCancel={() => {
        console.log('on cancel called');
      }}
      enableCameraPermissionModal={true}
      closeButton={
        <Button
          style={{
            right: '24px',
            position: 'relative',
          }}
          onClick={() => {
            console.log('onback called');
            onBack();
          }}
        >
          Back
        </Button>
      }
    />,
  );
  return null;
};

// See Authentication on where to get this sessionToken.
const Scanner = ({ onScanSuccess, onScanError, onBack }) => {
  console.log('sessionToken', sessionToken, process.env);
  // Render CardScanView
  return showScanner({
    onScanSuccess,
    onScanError,
    onBack,
  });
};

export default Scanner;
